import { PTDateToUTC } from '../../common/utils/dates';
import { formatName } from '../../common/utils/formatName';
import { formatPhone } from '../../common/utils/formatPhone';
import { parseClientLocation } from '../../common/utils/parseClientLocations';
import {
  convertDateDeliverBytoUTC,
  convertPSTtoUTC,
  convertUTCtoDBColumn,
  formatAmazonPSTtoUTC,
} from './convertPSTtoUTC';
import {
  fetchRollunIdByASIN,
  matchRegExp,
  checkIfInCatalog,
} from './raw-data-parser-utils';
import { Client, Order, Position } from '../../api-clients/types';
import { noop } from '../../../../../utils/common.utils';

const dealDataParsers = {
  'Ebay Plaisir': {},
  'Ebay Rollun': {},
  'Amazon Shoptimistic': {},
};

export type Marketplaces = keyof typeof dealDataParsers;

export type DealSchemas = 'order' | 'return' | 'return fba';

export type PartialClient = Pick<
  Client,
  | 'firstName'
  | 'name'
  | 'phone'
  | 'address1'
  | 'address2'
  | 'city'
  | 'state'
  | 'postalCode'
  | 'email'
>;

export interface ParsedOrder {
  order: Partial<Order>;
  client: PartialClient;
  positions: Pick<Position, 'article' | 'quantity' | 'cost' | 'name'>[];
}

export interface ParsedReturn {
  mpOrderNumber?: string;
  labelCreator: string;
  mpName: string;
  mpReturnNumber?: string | null;
  returnLocation?: string;
  mpDateOpened?: string;
  dealStatus?: string;
  resolution?: string;
  contentType?: string;
  positions?: Omit<Position, 'id' | 'dealId' | 'offerId' | 'cost' | 'name'>[];
  problem?: string | null;
  carrier?: string | null;
  tracknumber?: string;
  rma?: string;
}

export class RawDataParser {
  fromText(
    rawData: string,
    marketplace: Marketplaces,
    dealSchema: DealSchemas,
  ) {
    const dealDataParsers = {
      'Ebay Plaisir': {
        order: (data: string) => this.parseEbayOrder(data, 'Ebay Plaisir'),
        return: (data: string) => this.parseEbayReturn(data, 'Ebay Plaisir'),
        'return fba': noop,
      },
      'Ebay Rollun': {
        order: (data: string) => this.parseEbayOrder(data, 'Ebay Rollun'),
        return: (data: string) => this.parseEbayReturn(data, 'Ebay Rollun'),
        'return fba': noop,
      },
      'Amazon Shoptimistic': {
        order: (data: string) => this.parseAmazonOrder(data),
        return: (data: string) => this.parseAmazonReturn(data),
        'return fba': (data: string) => this.parseAmazonFbaReturn(data),
      },
    };

    return dealDataParsers[marketplace][dealSchema](rawData);
  }

  async parseAmazonOrder(input: string): Promise<ParsedOrder> {
    const ProblemDescription = [];
    const rawOrderDetails = input.match(/Order details [a-z0-9\-:# ]+/i);
    const orderDetails = rawOrderDetails ? rawOrderDetails[0] : null;
    const shippingDetails = matchRegExp(
      input,
      /Ship by:[a-z0-9\-:#,.\r\s]+Ship to/i,
      0,
    );
    const [fullMatch, clientDetails] =
      input.match(/Ship to\r?\n([\s\S]+?)Address Type/) || [];
    const mpOrderNumber = matchRegExp(orderDetails, /[0-9\-]+/, 0);
    if (!mpOrderNumber) {
      ProblemDescription.push(`Couldn't extract MpOrderNumber from data!`);
    }
    const dateShipByString = matchRegExp(
      shippingDetails,
      /Ship by:\r?\s?([a-z0-9\-:,. ]+)/i,
    );
    const dateDeliverByString = matchRegExp(
      shippingDetails,
      /Deliver by:\r?\s?([a-z0-9\-:,. ]+)/i,
    );
    const dateCrPaidString = matchRegExp(
      shippingDetails,
      /Purchase date:\r?\s?([a-z0-9\-:,. ]+)/i,
    );
    const mpShipMethod = matchRegExp(
      shippingDetails,
      /Shipping service:\r?\s?([a-z0-9\-:,. ]+)/i,
    );
    console.log('dateShipByString', dateShipByString);
    console.log('dateDeliverByString', dateDeliverByString);

    const [dateShipByFrom, dateShipByByTo] =
      dateShipByString?.split('to') || [];
    const [dateDeliverByFrom, dateDeliverByTo] =
      dateDeliverByString?.split('to') || [];
    const dateShipBy = PTDateToUTC(
      new Date(
        ((dateShipByByTo || dateShipByFrom) + ' 23:59').replace(/[,]/g, ''),
      ),
    )
      .toISOString()
      .replace('T', ' ')
      .replace('Z', '')
      .slice(0, -4);
    const dateDeliverBy = PTDateToUTC(
      new Date(
        ((dateDeliverByTo || dateDeliverByFrom) + ' 23:59').replace(/[,]/g, ''),
      ),
    )
      .toISOString()
      .replace('T', ' ')
      .replace('Z', '')
      .slice(0, -4);

    console.log('1235', clientDetails);

    const {
      firstName,
      street1,
      street2 = '',
      locationInfo,
    } = this.parseClientInfo(clientDetails);
    console.log('12356', locationInfo);
    const { city, state, zip } = parseClientLocation(locationInfo);
    if (!city || !state || !zip) {
      throw new Error(
        `Could not parse client location! Got city: [${city}] state: [${state}] zip: [${zip}]`,
      );
    }

    const regex = /ASIN:\s*(\w+)\s*[\s\S]*?Order Item ID:\s*(\d+)\s*[\s\S]*?(\d+)\s*(?:\$)?([\d,.]+)\s*[\s\S]*?/g;
    const positions = await this.parsePositions(regex, input, 'asin');

    const [, phone] = input.match(/Phone:\s*([^\n]+)/) || [];

    const { phone: phoneNumber } = formatPhone(phone);
    return {
      order: {
        mpName: 'Amazon Shoptimistic',
        mpOrderNumber: mpOrderNumber,
        mpShipMethod: mpShipMethod?.replace(/\s/g, ''),
        dateShipBy,
        dateDeliverBy,
        dateCrPayed: convertUTCtoDBColumn(
          formatAmazonPSTtoUTC(dateCrPaidString),
        ),
        problem:
          ProblemDescription.length > 0
            ? ProblemDescription.join(',')
            : undefined,
      },
      client: {
        firstName: formatName(firstName),
        name: formatName(firstName),
        phone: phoneNumber,
        address1: street1,
        address2: street2,
        city: city,
        state: state,
        postalCode: zip,
        email: null,
      },
      positions,
    };
  }

  async parseEbayOrder(
    input: string,
    marketplace: string,
  ): Promise<ParsedOrder> {
    const otherData = this.getNamedFieldsValues(
      input
        .split(/[\r\n]/)
        .filter((el) => !!el && el !== 'Ship by' && el !== 'Date buyer paid'),
    );
    const mpOrderNumber = matchRegExp(input, /Order no.\s*\n\s*([0-9-]+)/);
    const email = matchRegExp(input, /E-mail\s+([^\s]+)\s+Phone/);
    const mpShipMethodRaw = matchRegExp(
      input,
      /Shipping service\s+([\s\S]*?)\s+Tracking/,
    );
    const [, clientData, state, postalCode] =
      input.match(
        /Ship to\s+([\s\S]+?)\s*,\s*([A-Z]{2})\s*([\d-]+)\s*([^\n]+)\n([A-Z][a-z]+(?: [A-Z][a-z]+)*)\n(?:([^\n]+)\n)?/,
      ) || [];
    const clientDataSplited = clientData.split('\n');
    const isAddress2Exist = clientDataSplited.length === 4;
    const [fullName, address1] = clientDataSplited;
    const address2 = isAddress2Exist ? clientDataSplited[2] : '';
    const city = clientData.split('\n')[isAddress2Exist ? 3 : 2];

    const dateShipBy = otherData?.['Ship by']
      ? null
      : convertPSTtoUTC(otherData['Ship by']);
    const dateDeliverBy = !otherData['Estimated delivery date shown to buyer']
      ? null
      : convertDateDeliverBytoUTC(
          otherData['Estimated delivery date shown to buyer'],
        );

    const mpClientIdRaw = matchRegExp(
      input,
      /Buyer[\s\S]*?(\b[\w.]+\(\d+\))[\s\S]*?Show contact info/,
    );

    const mpClientId = mpClientIdRaw ? mpClientIdRaw.split('(')[0] : undefined;

    const regex = /(?<=Custom label \(SKU\): )(\w+)[\s\S]*?Item ID: (\d+)[\s\S]*?Quantity\s+(\d+)\([^)]*\)[\s\S]*?Price\s+\$(\d+\.\d+)/g;

    const positions = await this.parsePositions(regex, input, 'rid');

    const { phone } = formatPhone(otherData['Phone']);

    const mpShipMethodLowerCase = mpShipMethodRaw
      ?.toLowerCase()
      .split('shipping')[0]
      .trim();
    const mpShipMethod = mpShipMethodLowerCase
      ? mpShipMethodLowerCase.charAt(0).toUpperCase() +
        mpShipMethodLowerCase.slice(1)
      : undefined;

    return {
      order: {
        mpName: marketplace,
        mpOrderNumber,
        mpOrderNote: otherData["Buyer's note"],
        mpShipMethod,
        dateShipBy,
        dateDeliverBy,
        mpClientId,
      },
      client: {
        name: fullName,
        firstName: '',
        phone,
        email: email ? email : null,
        address1,
        address2,
        state,
        postalCode,
        city,
      },
      positions,
    };
  }

  async parseAmazonReturn(input: string): Promise<ParsedReturn> {
    let returnNumberMatch: string | undefined | null = matchRegExp(
      input,
      /rma:\s?(.+)/i,
    );
    if (returnNumberMatch === 'Rma will be generated once authorized') {
      returnNumberMatch = null;
    }

    const orderId = matchRegExp(input, /order id:\s?(.+)/i);
    const asin = matchRegExp(input, /ASIN:(\w+)/);
    const quantity = matchRegExp(input, /Return Quantity:(\w+)/);
    const rid = await fetchRollunIdByASIN(asin);

    const parsedPositions =
      quantity && rid ? { article: rid, quantity: Number(quantity) } : null;

    const approveDateMatch = matchRegExp(input, /approval date\s?(.+)/i);
    const mpDateOpened = approveDateMatch
      ? convertUTCtoDBColumn(new Date(approveDateMatch).toUTCString())
      : undefined;

    const trackingIdMatch = matchRegExp(input, /Tracking ID:\s?(.+)/i);
    const carrier = matchRegExp(input, /Shipping Carrier:\s?(.+)/i);
    const resolution = input.match(/Resolution:\s?(.+)/i);
    const rma = input.match(/RMA:\s?(.+)/i);

    const returnLocation = this.getReturnLocation(input);

    const dealStatus = this.getDealStatus(input);
    let deal: ParsedReturn = {
      mpOrderNumber: orderId,
      labelCreator: 'MP',
      returnLocation,
      mpName: 'Amazon_Shoptimistic',
      mpDateOpened,
      dealStatus,
      resolution: resolution ? resolution[1] : undefined,
      contentType: 'Deal',
      positions: parsedPositions ? [parsedPositions] : [],
      rma: rma && rma[1].length > 1 ? rma[1] : undefined,
      mpReturnNumber: null,
    };
    if (dealStatus === 'closed') {
      deal = {
        ...deal,
        problem: 'autorefund',
      };
    }

    if (dealStatus !== 'closed') {
      deal = {
        ...deal,
        mpReturnNumber: returnNumberMatch ? returnNumberMatch : null,
        carrier,
        tracknumber:
          trackingIdMatch && trackingIdMatch.length > 1
            ? trackingIdMatch
            : undefined,
      };
    }

    return deal;
  }

  async parseAmazonFbaReturn(input: string): Promise<ParsedReturn> {
    const orderId = matchRegExp(input, /order id \s?(.+)/i);
    const dateOpened = input.match(/Return authorized\s*([\d\/]+)/);
    let mpDateOpened;
    if (dateOpened || dateOpened?.[1]) {
      const [month, day, year] = dateOpened[1].split('/');
      mpDateOpened = [year, month, day].join('-') + ' 00:00:00';
    }

    const deal: any = {
      mpOrderNumber: orderId,
      mpName: 'Amazon_Shoptimistic',
      mpDateOpened,
      contentType: 'Deal',
      labelCreator: 'MP',
      mpReturnNumber: 'fba return',
      dealStatus: 'authorized_waiting_for_receiving',
    };

    return deal;
  }

  async parseEbayReturn(
    input: string,
    marketplace: string,
  ): Promise<ParsedReturn> {
    const mpOrderNumber = matchRegExp(input, /Order\s*\n\s*([0-9-]+)/);
    const mpReturnNumber = matchRegExp(input, /Return ID\s*\n\s*([0-9]+)/);
    const trackingRegex = /Tracking details\s*([\w\s]+):\s*([\w\d]+)/;
    const carrier = matchRegExp(input, trackingRegex, 1);
    const tracknumber = matchRegExp(input, trackingRegex, 2);

    const mpDateOpened = new Date()
      .toISOString()
      .replace('T', ' ')
      .replace('Z', '')
      .slice(0, -4);

    const deal: ParsedReturn = {
      carrier: carrier?.toUpperCase(),
      mpOrderNumber,
      mpReturnNumber,
      labelCreator: 'MP',
      dealStatus: 'authorized_waiting_for_receiving',
      mpDateOpened,
      tracknumber,
      mpName: marketplace.replace(' ', '_'),
    };

    return deal;
  }

  private getNamedFieldsValues(rows: string[]) {
    const namedFields: any = {
      'Order number': (str: string) =>
        str.match(/Order number ([0-9\-]+)/i)?.[1],
      "Buyer's note": (str: string) => str.replace(/Buyer's note:/i, '').trim(),
      'E-mail': (str: string) => str.replace(/E-mail/i, '').trim(),
      Phone: (str: string) => str.replace(/Phone/i, '').trim(),
      'Date buyer paid': (str: string) =>
        str
          .replace(/Date buyer paid/i, '')
          .match(/([a-z]+ [0-3]?[0-9], [0-9]{4})/i)?.[0]
          .replace(',', '')
          .trim(),
      'Shipping method': (str: string) =>
        str
          .replace(/Shipping method/i, '')
          .replace(/Standard Shipping/i, 'Standard')
          .trim(),
      'Shipping service': (str: string) =>
        str
          .replace(/Shipping service/i, '')
          .replace(/Standard Shipping/i, 'Standard')
          .trim(),
      'Ship to': (str: string) => str.replace(/Ship to/i, '').trim(),
      Street: (str: string) => str.replace(/Street/i, '').trim(),
      City: (str: string) => str.replace(/City/i, '').trim(),
      'State/province': (str: string) =>
        str.replace(/State\/province/i, '').trim(),
      'Zip code': (str: string) => str.replace(/Zip code/i, '').trim(),
      'Ship by': (str: string) =>
        str
          .match(/Ship by([^\n]+)/i)?.[0]
          .replace('at', '')
          .trim(),
      'Estimated delivery date shown to buyer': (str: string) =>
        str.match(/Estimated delivery date shown to buyer:([^\n]+)/)?.[1],
    };
    const otherData: any = {};
    const keys = Object.keys(namedFields);
    rows.forEach((row) => {
      for (const key of keys) {
        if (row.toLocaleLowerCase().includes(key.toLocaleLowerCase())) {
          otherData[key] = namedFields[key](row);
          break;
        }
      }
    });
    return otherData;
  }

  private parseClientInfo(str: string) {
    const rows = str.split(/\r?\n/).filter((str) => str.trim());
    const firstName = rows[0];
    const locationInfo = rows[rows.length - 1];
    const [street1, street2 = ''] = rows.slice(1, rows.length - 1);
    return {
      firstName,
      locationInfo,
      street1,
      street2,
    };
  }

  private async parsePositions(
    regex: RegExp,
    text: string,
    type: 'asin' | 'rid',
  ) {
    const items = [];

    let match;
    while ((match = regex.exec(text))) {
      const [, parsedArticle, mpOrderItemId, quantity, cost] = match;

      const article =
        type === 'rid'
          ? parsedArticle
          : await fetchRollunIdByASIN(parsedArticle);
      const catalogItem = await checkIfInCatalog(article);

      items.push({
        article,
        quantity: parseInt(quantity),
        cost: cost.replace(',', '.'),
        name: `${catalogItem.brand_id}  ${
          catalogItem.manufacturer_part_number
        }${mpOrderItemId ? `  ${mpOrderItemId}` : ''}`,
      });
    }

    return items;
  }

  private getReturnLocation(input: string) {
    const addressMatch = input.match(/Return Address(.+)/is);
    if (!addressMatch) {
      return undefined;
    }
    const [, state, zip] =
      addressMatch[1].match(/([A-Z]{2})\s?([0-9]{5})/) || [];
    const returnLocationMap = {
      'UT-84651': 'ret_UT_Thomas_1',
      'KY-40391': 'ret_KY_Andria_Wht',
      'TX-76114': 'ret_TX_Latasha_Msg',
      'TX-77039': 'ret_CN_Andriy_Zaboychenko',
    };
    const location = `${state}-${zip}` as keyof typeof returnLocationMap;
    return returnLocationMap[location];
  }

  private getDealStatus(input: string) {
    if (/Resolution:\s+(ReturnlessRefund|ReturnlessReplacement)/i.test(input)) {
      return 'closed';
    }
    if (/Resolution:\s+(Replacement|StandardRefund)/i.test(input)) {
      return 'authorized_waiting_for_receiving';
    }
    if (/Auto-authorized/i.test(input)) {
      return 'authorized_waiting_for_receiving';
    }
    return undefined;
  }
}
