import { useQuery } from 'react-query';
import { apiClientsReturnBags } from '../../api-clients/api-clients-return-bags';
const GET_DEAL_KEY = 'GET_DEAL_KEY';

export const useGetReturnBag = (id: string) => {
  return useQuery(
    [GET_DEAL_KEY, id],
    async () => {
      try {
        return await apiClientsReturnBags.getById(id);
      } catch (error) {
        const message = (error as Error).message;

        if (message.includes('not found')) {
          throw new Error(message);
        }
        throw new Error('Something went wrong. Try to re-login');
      }
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  );
};
