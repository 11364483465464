export function convertPSTtoUTC(PST: string) {
  const now = new Date();
  const parts = PST.replace('Ship by ', '')
    .split(' ')
    .filter((s) => !!s);

  // Extract the month, day, and time components
  const month = parts[0];
  const day = parseInt(parts[1], 10);
  const time = parts[2];

  // Extract the hours and minutes from the time component
  const timeParts = time.split(':');
  let hours = parseInt(timeParts[0], 10);
  const minutes = parseInt(timeParts[1], 10);

  // Convert the hours to 24-hour format if necessary
  if (time.indexOf('pm') !== -1 && hours !== 12) {
    hours += 12;
  }

  // Create a new Date object with the extracted components in PST time zone
  const datePST = new Date(
    now.getFullYear(),
    getMonthIndex(month),
    day,
    hours,
    minutes,
  );
  datePST.setHours(datePST.getHours() + 8); // Convert to UTC by subtracting 8 hours

  // Format the date as a string in the desired format
  const formattedDate = datePST
    .toISOString()
    .replace('T', ' ')
    .replace(/\.\d+Z/, '');

  return formattedDate;
}

export function convertDateDeliverBytoUTC(originalString: string) {
  const parts = originalString.split(' - ');
  const secondPart = parts[1];
  const match = secondPart?.match(/(\w{3}\s\d{1,2})/) || [];
  const md = !match ? null : match[1];
  const date = new Date(md + ' 00:00:00');
  const year = new Date().getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  return `${year}-${month}-${day} 07:59:00`;
}

function getMonthIndex(month: string) {
  // Convert the month name to a numeric index (0-based)
  const months = [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
  ];
  return months.indexOf(month.toLowerCase().substr(0, 3));
}

export function convertUTCtoDBColumn(utcDate: string | undefined) {
  if (!utcDate) return;
  const date = new Date(utcDate);

  const utcYear = date.getUTCFullYear();
  const utcMonth = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const utcDay = date.getUTCDate().toString().padStart(2, '0');
  const utcHours = date.getUTCHours().toString().padStart(2, '0');
  const utcMinutes = date.getUTCMinutes().toString().padStart(2, '0');
  const utcSeconds = date.getUTCSeconds().toString().padStart(2, '0');

  const formattedUTCDateTime = `${utcYear}-${utcMonth}-${utcDay} ${utcHours}:${utcMinutes}:${utcSeconds}`;
  return formattedUTCDateTime;
}

export function formatAmazonPSTtoUTC(dateString: string | null | undefined) {
  if (!dateString) return;
  const utcTime = new Date(dateString).toUTCString();

  return utcTime;
}
