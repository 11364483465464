import Axios from 'axios';
import { useQuery } from 'react-query';
export const useStatusRules = () => {
  return useQuery(
    'statusRules',
    async () => {
      const { data } = await Axios.get(
        'https://crm-callbacks.rollun.net/status_rules',
      );

      return data as Promise<Record<string, Record<string, string[]>>>;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};
